import React from "react";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState, useRef } from "react";
import { AuthContext } from "../../context/AuthContext";
import Cookies from "js-cookie";
import "./game.scss";
import LazyLoad from "react-lazyload";
import { CaretDownFilled } from "@ant-design/icons";
import BlurUpImage from "../../function/BlurUpImage";
import { useAudio } from '../../context/AudioContext';

function Game() {
    const location = useLocation();
    const navigate = useNavigate();
    const { isVertical, windowWidth, windowHeight} = useContext(AuthContext);
    const [gameSrc, setGameSrc] = useState('');
    const [loadingScreen, setLoadingScreen] = useState(true);
    const { item } = location.state || {};
    const { playAudio, changeVolume, pauseAudio, backgroundMusicOn } = useAudio();

    // State to manage the active class
    const [isTopBarActive, setIsTopBarActive] = useState(false);

    // Toggle the active state on click
    const handleToggle = () => {
        setIsTopBarActive(!isTopBarActive);
    };
  
    useEffect(() => {
      pauseAudio();
      setGameSrc(item.src);

      // Set a timer to close the loading screen after 30 seconds
      const timer = setTimeout(() => {
        setLoadingScreen(false);
      }, 10000); // 30000 milliseconds = 30 seconds
  
      // Cleanup function to clear the timeout if the component unmounts or item changes
      return () => clearTimeout(timer);
    }, [item]);
    

    const confirmGoBack = async () => {
        /*
        Swal.fire({
            text: "Confirmed to Exit",
            icon: "info",
            showCancelButton: true,
            color: "#fff",
            background: "#434343",
        }).then((result) => {
            if (result.isConfirmed) {
                navigate("/lobby");
            }
        });
        */
        changeVolume(Number(Cookies.get("Volume")) / 100)
        backgroundMusicOn();
        playAudio();
        navigate("/lobby");
    }

    return (
        <div className="game-container"
            style={{
                position: 'relative', // Make the container relative
                backgroundImage: "none" ,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                minHeight: "100vh"
            }}
        >

        <div className={`contact-us-float ${isTopBarActive ? 'active' : ''}`} onClick={handleToggle}>
          <img 
          src="https://game-platform.sgp1.digitaloceanspaces.com/bwg/settings/MB3.0_Setting_VERT-20.png"
          alt="Return Button"
          className="return-btn"
          onClick={()=>confirmGoBack()}
          />
          <div className="button">
            <div className="title">
              <CaretDownFilled />
            </div>
          </div>
        </div>

        <iframe id="gameFrame" style={{ width: windowWidth, height: windowHeight }} src={gameSrc} onLoadedData={() => {setLoadingScreen(false)}} allowFullScreen webkitallowfullscreen></iframe>
    
        {/* Overlay Image */}
        <img
            src={isVertical ? "https://game-platform.sgp1.digitaloceanspaces.com/bwg/loading/BWG%20Loading_VTC.gif" : "https://game-platform.sgp1.digitaloceanspaces.com/bwg/loading/BWG%20Loading_HORZ.gif"} // Replace with your overlay image path
            alt="Overlay"
            style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%', // Full width of the container
            height: '100%', // Full height of the container
            objectFit: 'cover', // Cover the area without stretching
            zIndex: 5, // Lower than the button, higher than the iframe
            pointerEvents: 'none', // Allow clicks to pass through to the iframe
            display: loadingScreen ? "" : "none"
            }}
        />
        </div>
    );
}

export default Game;
