import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./contentheader.scss";

function ContentHeader({title}) {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    return (
        <div className="ct-header-container">
            <div className="ct-header-back" onClick={() => navigate("/lobby")}>
                <span style={{cursor:'pointer'}}> {'< '+ t("back")}</span>    
            </div>
            <div className="ct-header-title">{title}</div>
            <div className="ct-header-empty" />
        </div>
    );
}

export default ContentHeader;
