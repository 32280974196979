import React, { useState } from 'react';

function BlurUpImage({ src, alt, width, height }) {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsLoaded(true); // Update state when the full-size image is loaded
  };

  return (
    <img
      src={src}
      alt={alt}
      onLoad={handleImageLoad}
      style={{
        width: '100%',
        height: '100%',
        filter: isLoaded ? 'none' : 'blur(10px)',
        transition: 'filter 0.3s ease',
      }}
    />
  );
}

export default BlurUpImage;