import { Modal } from "antd";
import React, { useContext, useState, useEffect } from "react";
import Cookies from "js-cookie";
import { AuthContext } from "../../../../context/AuthContext";
import { useAudio } from '../../../../context/AudioContext';
import "./angpaomodal.scss";
import { redeemPlayerBonus } from "../../../../services/player-api/PlayerServices"

function AngpaoModal({ angpaoVisible, setAngpaoVisible, amount, bonuses, isVertical }) {

    const { setBalance, setBonusGranted } = useContext(AuthContext);
    const { backgroundMusicOn } = useAudio();

    const [isLoading, setIsLoading] = useState(true);   
    const [isModalOpen, setIsModalOpen] = useState(false);

    function handleCancel() {
        setAngpaoVisible(false);
    }

    useEffect(() => {
        const total = bonuses.reduce((sum, bonus) => sum + bonus.amount, 0); // Sum up the amount
        setTotalAmount(total); // Store the total amount in state
    }, [angpaoVisible]);

    // State to track whether the button is clicked
    const [isClicked, setIsClicked] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0); // State to store the total amount


    // Function to handle button click
    const handleClick = async () =>  {
        try {
            setIsLoading(true);

            const bonusList = bonuses.map(({ srno, amount }) => ({ srno, amount }));

            await redeemPlayerBonus(
              {
                playerID: Cookies.get("PlayerID"),
                bonusList: bonusList
             }
            );    

            setIsClicked(true);
            await setTimeout(() => {
              setAngpaoVisible(false);
              setIsClicked(false);

              // Counter logic
              let targetValue = amount + totalAmount; // Replace with your desired value
              let currentValue = amount;
              let interval = setInterval(() => {
                if (currentValue >= targetValue) {
                  clearInterval(interval);
                } else {
                  currentValue += 1; // Increment the counter
                  setBalance(currentValue);
                }
                setBonusGranted(false);
              }, 20); // Adjust interval speed as necessary
              backgroundMusicOn();

            }, 2000); // Matches your animation durationx
        } catch (err) {
            //setError(err.message || "Failed to get Balance");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        isVertical ?
        (
        <Modal 
            className="v-angpao-modal-container" 
            closeIcon={false} 
            open={angpaoVisible} 
            onCancel={handleCancel} 
            centered 
            footer={null}
        ><div className="angpao-img">
        <img
            src="https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/AngPao-02.png"
            alt="Angpao"
            className={isClicked ? "modal-animated-image" : "modal-image"}
        />
        {/* Image Button */}
        <button
            className="image-button"
            onClick={handleClick}
        >
            {/* Text Above the Image */}
            <span
                className={isClicked ? "animated-text" : "unanimated-text"} // Apply class when clicked
            >
                {totalAmount.toFixed(2)}
            </span>
            <img
                src="https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/AngPao-btn.png"
                alt="Button"
                className={isClicked ? "receive-btn" : ""}
                style={{ width: "60vw", height: "8vh", cursor: "pointer" }} // Fixed size
            />
        </button>
        </div>
        </Modal>
        ) :
        (
            <Modal 
                className="h-angpao-modal-container" 
                closeIcon={false} 
                open={angpaoVisible} 
                onCancel={handleCancel} 
                centered 
                footer={null}
            ><div className="angpao-img">
            <img
                src="https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/AngPao-02.png"
                alt="Angpao"
                className={isClicked ? "modal-animated-image" : "modal-image"}
            />
            {/* Image Button */}
            <button
                className="image-button"
                onClick={handleClick}
            >
                {/* Text Above the Image */}
                <span
                    className={isClicked ? "animated-text" : "unanimated-text"} // Apply class when clicked
                >
                    {totalAmount.toFixed(2)}
                </span>
                <img
                    src="https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/AngPao-btn.png"
                    alt="Button"
                    className={isClicked ? "receive-btn" : ""}
                    style={{ width: "15vw", height: "10vh", cursor: "pointer" }} // Fixed size
                />
            </button>
            </div>
            </Modal>
        )
    );
}

export default AngpaoModal;