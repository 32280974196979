import { Col, Row, Spin, Table, message } from "antd";
import { useEffect, useState } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ContentHeader from "../../components/content-header/ContentHeader";
import { getGameLog } from "../../services/lobby-api/LobbyServices"
import dayjs from "dayjs";
import Cookies from "js-cookie";
import "./transaction.scss";

function GameLog() {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);

    const [apiData, setApiData] = useState([]);
    const [apiDataPagination, setApiDataPagination] = useState(undefined);

    useEffect(() => {
        return () => {
            getGameLogHistory(1, 10);
        };
    }, []);
    
    async function getGameLogHistory(page = 1, pageSize = 10) {
        setIsLoading(true);
        try {
            const data = await getGameLog(
                {
                  playerID: Cookies.get("PlayerID")
                }
            );
            setApiData(data);
        } catch (error) {
            console.log(error);
            // message.error({ content: error?.response?.data?.message, key: error?.response?.data?.message });
        }
        setIsLoading(false);
    }
    
    async function formatNumber(value) {
        return Number(value).toLocaleString(undefined, {
            minimumFractionDigits: 2,
        });
    }

    function handleSwitchLog() {
        navigate(`/wallet`);
    }

    const columns = [
        {
            title: "#",
            ellipsis: true,
            render: (text, record, index) => {
                return index + 1;
            },
        },
        {
            title: t("game"),
            dataIndex: "game",
            ellipsis: true,
            render: (text) => text,
        },
        {
            title: t("category"),
            dataIndex: "provider",
            ellipsis: true,
            render: (text) => { if(text == "t9") { return "LIVE"} else { return "SLOT" }},
        },
        {
            title: t("beforeBalance"),
            dataIndex: "balanceBef",
            ellipsis: true,
            render: (text) => text,
        },
        {
            title: t("bet"),
            dataIndex: "amount",
            ellipsis: true,
            render: (text) => text,
        },
        {
            title: t("win"),
            dataIndex: "amount",
            ellipsis: true,
            render: (text) => text,
        },
        {
            title: t("afterBalance"),
            dataIndex: "balanceAft",
            ellipsis: true,
            render: (text) => text,
        },
        {
            title: t("remark"),
            dataIndex: "remark",
            ellipsis: true,
        },
        {
            title: t("createDate"),
            dataIndex: "createDate",
            ellipsis: true,
            render: (text) => dayjs(text).format("DD-MM-YYYY HH:mm:ss"),
        },
    ];

    useEffect(() => {
        getGameLogHistory(1, 10);
    }, []);

    return (
        <div className="transaction-container">
            <div className="tc-header-container">
                <ContentHeader title={t("transaction")} />
            </div>
            <div className="neon-hr" />
            <Row className="tab" justify="center" align="middle">
                <Col xxl={18} xs={23}>
                    <Row justify="space-around" align="middle">
                        <Col>
                            <div className="item">
                                {/* <img src={homeIcon} alt="home" /> */}
                                {t("gameLog")}
                            </div>
                        </Col>
                        <Col>
                            <div className="item" onClick={handleSwitchLog}>
                                {/* <img src={suggestionIcon} alt="suggestion" /> */}
                                {t("walletLog")}
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>  
            <div className="neon-hr" />
            <div className="tc-main-container">
                <div className="tc-table">
                <div className="transaction-history">
                    <Spin spinning={isLoading}>
                        <Table
                            columns={columns}
                            dataSource={apiData}
                            rowKey="srno"
                            scroll={{ x: true }}
                            pagination={{
                                showLessItems: true,
                                current: apiDataPagination?.currentPage,
                                total: apiDataPagination?.total,
                                showSizeChanger: false,
                                pageSize: apiDataPagination?.perPage,
                                onChange: (page, pageSize) => getGameLogHistory(page, pageSize),
                            }}
                        />
                    </Spin>
                </div>
                </div>
            </div>
        </div>
    );
}

export default GameLog;
