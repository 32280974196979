import React, { useContext, useState, useEffect, useMemo } from "react";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import "./lobby.scss";
import Cookies from "js-cookie";
import GameList from "./component/game-list/GameList";
import { AuthContext } from "../../context/AuthContext";
import { useAudio } from '../../context/AudioContext';
import GameCat from "./component/game-cat/GameCat";
import Announcement from "./component/announcement/Announcement";
import Footer from "./component/footer/Footer";
import Header from "./component/header/Header";
import AngpaoModal from "./component/angpao-modal/AngpaoModal";
import LogoutModal from "./component/logout-modal/LogoutModal";
import { LoginModal } from "./component/login-modal/LoginModal";
import SettingsModal from "./component/settings-modal/SettingsModal";
import { getPlayerBalance, getPlayerBonus } from "../../services/player-api/PlayerServices"

function Lobby() {
    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const { isVertical, balance, bonusGranted, setBonusGranted, settingsVisible, setSettingsVisible, firstLoad, setFirstLoad } = useContext(AuthContext);
    const { playAudio, changeVolume, angpaoMusicOn, backgroundMusicOn } = useAudio();

    const [bonuses, setBonuses] = useState([]);

    const [logoutVisible, setLogoutVisible] = useState(false);
    const [angpaoVisible, setAngpaoVisible] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            detectBonus();
            
            if(firstLoad){
                changeVolume(Number(Cookies.get("Volume")) / 100)
                backgroundMusicOn();
                playAudio();
                setFirstLoad(false);
            }
  
            /*
            if(firstLoad){
                Swal.fire({
                    text: t("loginSuccessMsg"),
                    icon: "success",
                    color: "#fff",
                    background: "#434343",
                    customClass: {
                        popup: 'swal-popup-border', // Custom class for popup
                    },
                }).then((result) => {
                    if (result.isConfirmed) {
                        setFirstLoad(false);
                        changeVolume(Number(Cookies.get("Volume")) / 100)
                        playAudio();
                    }
                });
            }
            */
           
        }, 500); // 30000 milliseconds = 30 seconds
    
        // Cleanup function to clear the timeout if the component unmounts or item changes
        return () => clearTimeout(timer);
    }, [])

    const detectBonus = async () => {
        try {
            setIsLoading(true);

            setBonusGranted(false);
            const result = await getPlayerBonus(
              {
                playerID: Cookies.get("PlayerID")
              }
            );    

            if (result.length > 0) {
                // Handle the case where there are bonuses
                //console.log("Bonuses detected:", result);
                // You can update state or render the bonuses in your UI here
                setBonuses(result);
                setBonusGranted(true);
                angpaoMusicOn();
            } else {
                // Handle the case where there are no bonuses
                console.log("No bonuses found.");
            }
        } catch (err) {
            //setError(err.message || "Failed to get Balance");
        } finally {
            setIsLoading(false);
        }
    }

    const openAngpao = async () =>{
      try {
        setAngpaoVisible(true);
        return;
      } catch (err) {
        setError(err.message || "Failed to open Angpao");
      } finally {
        setIsLoading(false);
      }
    }

    const verticalLayout = useMemo(() => {
        return (
            <div className="v-lobby-container">
                <div className="lb-header-container">
                    <Header setLogoutVisible={setLogoutVisible} />
                </div>
                <div className="lb-announcement-container">
                    <Announcement />
                </div>
                <div className="lb-footer-container">
                    <Footer />
                </div>
                <div className="lb-game-cat-container">
                    <GameCat />
                </div>
                <div className="lb-game-container">
                    <GameList />
                </div>
                <div className="lb-empty-container">
                </div>
                <div style={{display : bonusGranted ? "" : "none"}}>
                    <img src="https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/AngPao-01.png" 
                    className="overlay-img" 
                    style={{display: angpaoVisible ? "none" : "grid"}} 
                    alt="Overlay Image" />
                    <div className="click-area" onClick={openAngpao}></div>
                    <AngpaoModal angpaoVisible={angpaoVisible} setAngpaoVisible={setAngpaoVisible} amount={balance} bonuses={bonuses} isVertical={isVertical} />
                </div>
                <SettingsModal settingsVisible={settingsVisible} setSettingsVisible={setSettingsVisible} />
                <LogoutModal logoutVisible={logoutVisible} />
                {/*<LoginModal show={firstLoad} setShow={setFirstLoad} />*/}
            </div>
        );
    }, [isVertical, angpaoVisible, bonusGranted, balance, bonuses, settingsVisible, logoutVisible]);
    
    const horizontalLayout = useMemo(() => {
        return (
            <div className="h-lobby-container">
                <div className="lb-header-container">
                    <Header />
                </div>
                <div className="lb-main-container">
                    <div className="lb-game-cat-container">
                        <GameCat />
                    </div>
                    <div className="lb-sub-container">
                        <div className="lb-announcement-container">
                            <Announcement />
                        </div>
                        <div className="lb-game-container">
                            <GameList />
                        </div>
                    </div>
                </div>
                <div className="lb-footer-container">
                    <Footer />
                </div>
                <div style={{display : bonusGranted ? "" : "none"}}>
                    <img src="https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/AngPao-01.png" 
                    className="overlay-img" 
                    style={{display: angpaoVisible ? "none" : "grid"}} 
                    alt="Overlay Image" />
                    <div className="click-area" onClick={openAngpao}></div>
                    <AngpaoModal angpaoVisible={angpaoVisible} setAngpaoVisible={setAngpaoVisible} amount={balance} bonuses={bonuses} isVertical={isVertical} />
                </div>
                <SettingsModal settingsVisible={settingsVisible} setSettingsVisible={setSettingsVisible} />
                <LogoutModal logoutVisible={logoutVisible} setLogoutVisible={setLogoutVisible} />
                {/*<LoginModal show={firstLoad} setShow={setFirstLoad} />*/}
            </div>
        );
    }, [isVertical, angpaoVisible, bonusGranted, balance, bonuses, settingsVisible, logoutVisible]);
    
    return isVertical ? verticalLayout : horizontalLayout;  
}

export default Lobby;
