import React from "react";
import "./suggestion.scss";
import Header from "../lobby/component/header/Header";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ContentHeader from "../../components/content-header/ContentHeader";

function Suggestion() {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    return (
        <div className="suggestion-container">
            <div className="sg-header-container">
                <ContentHeader title={t("suggestion")} />
            </div>
            <div className="sg-main-container">
                <div className="sg-type">
                    <div className="sg-title">
                        <p>{t("suggestionType")} :</p>
                    </div>

                    <div className="sg-box"></div>
                </div>
                <div className="sg-message">
                    <div className="sg-title">
                        <p>{t("message")} :</p>
                    </div>
                    <div className="sg-box"></div>
                </div>
            </div>
        </div>
    );
}

export default Suggestion;
