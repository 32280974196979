import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Ticker from "react-ticker";
import "./announcement.scss";
import { AuthContext } from "../../../../context/AuthContext";

function Announcement() {
    const { t, i18n } = useTranslation();
    const { isVertical, settingsVisible, setSettingsVisible } = useContext(AuthContext);

    return (
        <div className={`${isVertical ? "v" : "h"}-annc-container`}>
            <div className="annc-wrapper">
                <div className="annc-content">
                    <Ticker offset="run-in" speed={10} mode="await">
                        {({ index }) => <p>{t("annoucement")}</p>}
                    </Ticker>
                </div>
            </div>
            <div className={`menu-btn-container ${isVertical}`} onClick={() => {setSettingsVisible(!settingsVisible)}}>
                <img className="menu-btn-img" src="https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/setting.png" alt="" />
            </div>
            <div className="annc-btn-container">
                <img className="annc-btn-img" src="https://game-platform.sgp1.digitaloceanspaces.com/bwg/common/announcment.png" alt="" />
            </div>
        </div>
    );
}

export default Announcement;
