import Cookies from "js-cookie";

export function setPlayerInfo(result) {

    if(result.playerID !== undefined && result.playerID != "" && result.playerID != "undefined"){
        Cookies.set("PlayerID", result.playerID);
        //console.log("set Player ID : " + result.playerID)
    }
    if(result.username !== undefined && result.username != "" && result.username != "undefined"){
        Cookies.set("UserName", result.username);
        //console.log("set Username : " + result.username)
    }
    if(result.token !== undefined && result.token != "" && result.token != "undefined"){
        Cookies.set("Token", result.token);
        //console.log("set Token : " + result.token)
    }
    if(result.music !== undefined && result.music != "" && result.music != "undefined"){
        Cookies.set("Volume", result.music);
        //console.log("set Volume : " + result.music)
    }
    if(result.lang !== undefined && result.lang != "" && result.lang != "undefined"){
        Cookies.set("Language", result.lang);
        //console.log("set Language : " + result.lang)
    }
}