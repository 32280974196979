import "antd/dist/reset.css";
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import PageNotFound from "./pages/other/PageNotFound";
import Lobby from "./pages/lobby/Lobby";
import Login from "./pages/login/Login";
import Game from "./pages/game/Game";
import MainLayout from "./components/main-layout/MainLayout";
import Suggestion from "./pages/suggestion/Suggestion";
import GameLog from "./pages/transaction/GameLog";
import Transaction from "./pages/transaction/Transaction";
import React, { useEffect } from "react";
import Cookies from "js-cookie";    

function App() {
    const Protected = ({ children }) => {
        // const { userData } = useContext(AuthContext);

        if (true) {
            return children;
        } else {
            return <Navigate to="/unauthorized-access" replace />;
        }
    };

    const router = createBrowserRouter([
        {
            element: <MainLayout />,
            errorElement: <PageNotFound />,
            children: [
                {
                    path: "/",
                    element: <Login />,
                },
                {
                    path: "/lobby",
                    element: <Lobby />,
                },
                {
                    path: "/game",
                    element: <Game />,
                },
                {
                    path: "/suggestion",
                    element: <Suggestion />,
                },
                {
                    path: "/transaction",
                    element: <GameLog />,
                },
                {
                    path: "/wallet",
                    element: <Transaction />,
                },
                // {
                //     path: "/user/list",
                //     element: <Protected children={<UserList />} />,
                // },
            ],
        },
    ]);

    return <RouterProvider router={router} />;
}

export default App;
