import { Modal } from "antd";
import React from "react";
import "./logoutmodal.scss";

function LogoutModal({ logoutVisible, setLogoutVisible }) {
    function handleCancel() {
        setLogoutVisible(false);
    }
    return (
        <Modal className="logout-modal-container" closeIcon={false} open={logoutVisible} onCancel={handleCancel} centered footer={null}>
            <div className="logout-modal-wrapper"></div>
        </Modal>
    );
}

export default LogoutModal;
