import { createContext, useEffect, useState } from "react";
import { validatePlayerToken } from "../services/player-api/PlayerServices";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

export const AuthContext = createContext();

export const AuthContextProvider = (props) => {
    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [firstLoad, setFirstLoad] = useState(true);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [isVertical, setIsVertical] = useState(false);
    const [category, setCategory] = useState("slot");
    const [playerData, setPlayerData] = useState({});
    const [balance, setBalance] = useState(0);
    const [bonusGranted, setBonusGranted] = useState(false);
    const [settingsVisible, setSettingsVisible] = useState(false);
    const [isLogin, setIsLogin] = useState(null); // Start with `null` or `undefined` to indicate loading

    useEffect(() => {
        i18n.changeLanguage(Cookies.get("Language"))
        if(isLogin){
            getPlayerActive();
        }
        handleValidateUser();
    }, []);

    useEffect(() => {
        function handleWindowWidth() {
            setWindowWidth(window.innerWidth);
            setWindowHeight(window.innerHeight);
            setIsVertical(windowWidth <= windowHeight);
        }
        handleWindowWidth();
        window.addEventListener("resize", handleWindowWidth);

        return () => {
            window.removeEventListener("resize", handleWindowWidth);
        };
    }, [windowWidth]);

    // useEffect(() => {
    //     handleValidateUserToken();
    // }, []);

    async function getPlayerActive(){
        const script = document.createElement('script');
        script.src = "https://cdn.socket.io/4.8.0/socket.io.min.js";
        script.async = true;
      
        script.onload = () => {
          const socket = window.io(`https://doc.bwg688.co?playerID=${Cookies.get("PlayerID")}`);
          // You can now use the socket instance here.
        };
      
        document.body.appendChild(script);
      
        return () => {
          document.body.removeChild(script);
        };   
    }

    async function handleValidateUser() {
        try {
            const playerId = Cookies.get("PlayerID");
            const token = Cookies.get("Token");
            
            if (
                playerId === undefined || 
                playerId.trim() == "" || 
                playerId.trim() == "undefined" || 
                token === undefined || 
                token.trim() == "" || 
                token.trim() == "undefined") {
                setIsLogin(false);
            } else {
                setIsLogin(true);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }

    }

    async function handleValidateUserToken() {
        const finalUserInput = {};
        try {
            const result = await validatePlayerToken(finalUserInput);
            if (result) {
                setPlayerData(result.data);
                localStorage.setItem("playerID", result.data.playerID);
                localStorage.setItem("token", result.data.token);
                setIsLogin(true);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }
    
    if (isLogin) {
        <Navigate to="/lobby" replace />;
    } else {
        <Navigate to="/" replace />;
    }

    const values = { windowWidth, windowHeight, isVertical, category, setCategory, playerData, balance, setBalance, bonusGranted, setBonusGranted, settingsVisible, setSettingsVisible, isLogin, setIsLogin, getPlayerActive, firstLoad, setFirstLoad };

    return <AuthContext.Provider value={values}>{props.children}</AuthContext.Provider>;
};
