import apiBaseUrl from "../ApiBaseUrl";

export async function getLobbyGameCat(userInput) {
    const response = await apiBaseUrl().post("/lobby/game-cat", userInput);
    const data = response.data;

    return data;
}

export async function getLobbyGame(userInput) {
    const response = await apiBaseUrl().get("/games/getGameList", userInput);
    const data = response.data;

    return data;
}


export async function launchGame(userInput) {
    // Construct query parameters string
    const params = new URLSearchParams(userInput).toString();
    // Send GET request with query parameters
    const response = await apiBaseUrl().get(`/games/launch-game?${params}`);
    const data = response.data;

    return data;
}

export async function updateSettings(userInput) {
    const response = await apiBaseUrl().post("/games/update-setting", userInput);
    const data = response.data;

    return data;
}

export async function getGameLog(userInput) {
    // Construct query parameters string
    //const params = new URLSearchParams(userInput).toString();
    // Send GET request with query parameters
    //const response = await apiBaseUrl().get(`/players/balance?${params}`);

    const response = await apiBaseUrl().post("/games/game-logs", userInput);
    const data = response.data;

    return data;
}

export async function getTransaction(userInput) {
    // Construct query parameters string
    //const params = new URLSearchParams(userInput).toString();
    // Send GET request with query parameters
    //const response = await apiBaseUrl().get(`/players/balance?${params}`);
    const response = await apiBaseUrl().post("/games/wallet-logs", userInput);
    const data = response.data;

    return data;
}